import $ from 'jquery';

$('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
    if (!$(this).next().hasClass('show')) {
        $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
    }
    var $subMenu = $(this).next(".dropdown-menu");
    $subMenu.toggleClass('show');


    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
        $('.dropdown-submenu .show').removeClass("show");
    });


    return false;
});


function isScroll() {
    var top = 50;
    return $(window).scrollTop() >= top;
}

// $(document).ready( function () {
    var nav = $('#navbarHomePage');
    var scrollSrc = "/assets/ketomix-front-style/imgs/ketomix.png";
    var initialSrc = "/assets/ketomix-front-style/imgs/ketomix-wh.png";

    if (isScroll()) {
        nav.addClass('navbar-white shadow');
    //    $(".brand-logo").attr("src", scrollSrc);
    } else {
        nav.removeClass('navbar-white shadow');
     //   $(".brand-logo").attr("src", initialSrc);
    }
// });


$(window).scroll(function () {
    var nav = $('#navbarHomePage');
    var scrollSrc = "/assets/ketomix-front-style/imgs/ketomix.png";
    var initialSrc = "/assets/ketomix-front-style/imgs/ketomix-wh.png";

    if (isScroll()) {
        nav.addClass('navbar-white shadow');
      //  $(".brand-logo").attr("src", scrollSrc);
    } else {
        nav.removeClass('navbar-white shadow');
      //  $(".brand-logo").attr("src", initialSrc);
    }
});